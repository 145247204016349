export const COUNTRIES_MULTISELECT = [

    { "label": "afghanistan", "value": "Afghanistan" },
    { "label": "aland_islands", "value": "Aland Islands" },
    { "label": "albania", "value": "Albania" },
    { "label": "algeria", "value": "Algeria" },
    { "label": "american_samoa", "value": "American Samoa" },
    { "label": "andorra", "value": "Andorra" },
    { "label": "angola", "value": "Angola" },
    { "label": "anguilla", "value": "Anguilla" },
    { "label": "antarctica", "value": "Antarctica" },
    { "label": "antigua_and_barbuda", "value": "Antigua and Barbuda" },
    { "label": "argentina", "value": "Argentina" },
    { "label": "armenia", "value": "Armenia" },
    { "label": "aruba", "value": "Aruba" },
    { "label": "australia", "value": "Australia" },
    { "label": "austria", "value": "Austria" },
    { "label": "azerbaijan", "value": "Azerbaijan" },
    { "label": "bahamas", "value": "Bahamas" },
    { "label": "bahrain", "value": "Bahrain" },
    { "label": "bangladesh", "value": "Bangladesh" },
    { "label": "barbados", "value": "Barbados" },
    { "label": "belarus", "value": "Belarus" },
    { "label": "belgium", "value": "Belgium" },
    { "label": "belize", "value": "Belize" },
    { "label": "benin", "value": "Benin" },
    { "label": "bermuda", "value": "Bermuda" },
    { "label": "bhutan", "value": "Bhutan" },
    { "label": "bolivia", "value": "Bolivia" },
    { "label": "bonaire", "value": "Bonaire" },
    { "label": "bosnia_and_herzegovina", "value": "Bosnia and Herzegovina" },
    { "label": "botswana", "value": "Botswana" },
    { "label": "bouvet_island", "value": "Bouvet Island" },
    { "label": "brazil", "value": "Brazil" },
    { "label": "brunei_darussalam", "value": "Brunei Darussalam" },
    { "label": "bulgaria", "value": "Bulgaria" },
    { "label": "burkina_faso", "value": "Burkina Faso" },
    { "label": "burundi", "value": "Burundi" },
    { "label": "cabo_verde", "value": "Cabo Verde" },
    { "label": "cambodia", "value": "Cambodia" },
    { "label": "cameroon", "value": "Cameroon" },
    { "label": "canada", "value": "Canada" },
    { "label": "cayman_islands", "value": "Cayman Islands" },
    { "label": "central_african_republic", "value": "Central African Republic" },
    { "label": "chad", "value": "Chad" },
    { "label": "chile", "value": "Chile" },
    { "label": "china", "value": "China" },
    { "label": "christmas_island", "value": "Christmas Island" },
    { "label": "cocos_islands", "value": "Cocos Islands" },
    { "label": "colombia", "value": "Colombia" },
    { "label": "comoros", "value": "Comoros" },
    { "label": "congo", "value": "Congo" },
    { "label": "congo", "value": "Congo" },
    { "label": "cook_islands", "value": "Cook Islands" },
    { "label": "costa_rica", "value": "Costa Rica" },
    { "label": "cote_d'ivoire", "value": "Cote d'Ivoire" },
    { "label": "croatia", "value": "Croatia" },
    { "label": "cuba", "value": "Cuba" },
    { "label": "curacao", "value": "Curacao" },
    { "label": "cyprus", "value": "Cyprus" },
    { "label": "czech_republic", "value": "Czech Republic" },
    { "label": "denmark", "value": "Denmark" },
    { "label": "djibouti", "value": "Djibouti" },
    { "label": "dominica", "value": "Dominica" },
    { "label": "dominican_republic", "value": "Dominican Republic" },
    { "label": "ecuador", "value": "Ecuador" },
    { "label": "egypt", "value": "Egypt" },
    { "label": "el_salvador", "value": "El Salvador" },
    { "label": "equatorial_guinea", "value": "Equatorial Guinea" },
    { "label": "eritrea", "value": "Eritrea" },
    { "label": "estonia", "value": "Estonia" },
    { "label": "ethiopia", "value": "Ethiopia" },
    { "label": "falkland_islands", "value": "Falkland Islands" },
    { "label": "faroe_islands", "value": "Faroe Islands" },
    { "label": "fiji", "value": "Fiji" },
    { "label": "finland", "value": "Finland" },
    { "label": "france", "value": "France" },
    { "label": "french_guiana", "value": "French Guiana" },
    { "label": "french_polynesia", "value": "French Polynesia" },
    { "label": "french_southern_territories", "value": "French Southern Territories" },
    { "label": "gabon", "value": "Gabon" },
    { "label": "gambia", "value": "Gambia" },
    { "label": "georgia", "value": "Georgia" },
    { "label": "germany", "value": "Germany" },
    { "label": "ghana", "value": "Ghana" },
    { "label": "gibraltar", "value": "Gibraltar" },
    { "label": "greece", "value": "Greece" },
    { "label": "greenland", "value": "Greenland" },
    { "label": "grenada", "value": "Grenada" },
    { "label": "guadeloupe", "value": "Guadeloupe" },
    { "label": "guam", "value": "Guam" },
    { "label": "guatemala", "value": "Guatemala" },
    { "label": "guernsey", "value": "Guernsey" },
    { "label": "guinea", "value": "Guinea" },
    { "label": "guinea-bissau", "value": "Guinea-Bissau" },
    { "label": "guyana", "value": "Guyana" },
    { "label": "haiti", "value": "Haiti" },
    { "label": "heard_and_mcdonald_islands", "value": "Heard and McDonald Islands" },
    { "label": "holy_see", "value": "Holy See" },
    { "label": "honduras", "value": "Honduras" },
    { "label": "hong_kong", "value": "Hong Kong" },
    { "label": "hungary", "value": "Hungary" },
    { "label": "iceland", "value": "Iceland" },
    { "label": "india", "value": "India" },
    { "label": "indonesia", "value": "Indonesia" },
    { "label": "iran", "value": "Iran" },
    { "label": "iraq", "value": "Iraq" },
    { "label": "ireland", "value": "Ireland" },
    { "label": "isle_of_man", "value": "Isle of Man" },
    { "label": "israel", "value": "Israel" },
    { "label": "italy", "value": "Italy" },
    { "label": "jamaica", "value": "Jamaica" },
    { "label": "japan", "value": "Japan" },
    { "label": "jersey", "value": "Jersey" },
    { "label": "jordan", "value": "Jordan" },
    { "label": "kazakhstan", "value": "Kazakhstan" },
    { "label": "kenya", "value": "Kenya" },
    { "label": "kiribati", "value": "Kiribati" },
    { "label": "Southkorea", "value": "South Korea" },
    { "label": "Northkorea", "value": "North Korea" },
    { "label": "kuwait", "value": "Kuwait" },
    { "label": "kyrgyzstan", "value": "Kyrgyzstan" },
    { "label": "lao_peoples", "value": "Lao People's" },
    { "label": "latvia", "value": "Latvia" },
    { "label": "lebanon", "value": "Lebanon" },
    { "label": "lesotho", "value": "Lesotho" },
    { "label": "liberia", "value": "Liberia" },
    { "label": "libya", "value": "Libya" },
    { "label": "liechtenstein", "value": "Liechtenstein" },
    { "label": "lithuania", "value": "Lithuania" },
    { "label": "luxembourg", "value": "Luxembourg" },
    { "label": "macao", "value": "Macao" },
    { "label": "macedonia", "value": "Macedonia" },
    { "label": "madagascar", "value": "Madagascar" },
    { "label": "malawi", "value": "Malawi" },
    { "label": "malaysia", "value": "Malaysia" },
    { "label": "maldives", "value": "Maldives" },
    { "label": "mali", "value": "Mali" },
    { "label": "malta", "value": "Malta" },
    { "label": "marshall_islands", "value": "Marshall Islands" },
    { "label": "martinique", "value": "Martinique" },
    { "label": "mauritania", "value": "Mauritania" },
    { "label": "mauritius", "value": "Mauritius" },
    { "label": "mayotte", "value": "Mayotte" },
    { "label": "mexico", "value": "Mexico" },
    { "label": "micronesia", "value": "Micronesia" },
    { "label": "moldova", "value": "Moldova" },
    { "label": "monaco", "value": "Monaco" },
    { "label": "mongolia", "value": "Mongolia" },
    { "label": "montenegro", "value": "Montenegro" },
    { "label": "montserrat", "value": "Montserrat" },
    { "label": "morocco", "value": "Morocco" },
    { "label": "mozambique", "value": "Mozambique" },
    { "label": "myanmar", "value": "Myanmar" },
    { "label": "namibia", "value": "Namibia" },
    { "label": "nauru", "value": "Nauru" },
    { "label": "nepal", "value": "Nepal" },
    { "label": "netherlands", "value": "Netherlands" },
    { "label": "new_caledonia", "value": "New Caledonia" },
    { "label": "new_zealand", "value": "New Zealand" },
    { "label": "nicaragua", "value": "Nicaragua" },
    { "label": "niger", "value": "Niger" },
    { "label": "nigeria", "value": "Nigeria" },
    { "label": "niue", "value": "Niue" },
    { "label": "norfolk_island", "value": "Norfolk Island" },
    { "label": "northern_mariana_islands", "value": "Northern Mariana Islands" },
    { "label": "norway", "value": "Norway" },
    { "label": "oman", "value": "Oman" },
    { "label": "pakistan", "value": "Pakistan" },
    { "label": "palau", "value": "Palau" },
    { "label": "palestine", "value": "Palestine" },
    { "label": "panama", "value": "Panama" },
    { "label": "papua_new_guinea", "value": "Papua New Guinea" },
    { "label": "paraguay", "value": "Paraguay" },
    { "label": "peru", "value": "Peru" },
    { "label": "philippines", "value": "Philippines" },
    { "label": "pitcairn", "value": "Pitcairn" },
    { "label": "poland", "value": "Poland" },
    { "label": "portugal", "value": "Portugal" },
    { "label": "puerto_rico", "value": "Puerto Rico" },
    { "label": "qatar", "value": "Qatar" },
    { "label": "reunion", "value": "Reunion" },
    { "label": "romania", "value": "Romania" },
    { "label": "russian_federation", "value": "Russian Federation" },
    { "label": "rwanda", "value": "Rwanda" },
    { "label": "saint_barthelemy", "value": "Saint Barthelemy" },
    { "label": "saint_helena", "value": "Saint Helena" },
    { "label": "saint_kitts_and_nevis", "value": "Saint Kitts and Nevis" },
    { "label": "saint_lucia", "value": "Saint Lucia" },
    { "label": "saint_martin", "value": "Saint Martin" },
    { "label": "saint_pierre_and_miquelon", "value": "Saint Pierre and Miquelon" },
    { "label": "saint_vincent_and_the_grenadines", "value": "Saint Vincent and the Grenadines" },
    { "label": "samoa", "value": "Samoa" },
    { "label": "san_marino", "value": "San Marino" },
    { "label": "sao_tome_and_principe", "value": "Sao Tome and Principe" },
    { "label": "saudi_arabia", "value": "Saudi Arabia" },
    { "label": "senegal", "value": "Senegal" },
    { "label": "serbia", "value": "Serbia" },
    { "label": "seychelles", "value": "Seychelles" },
    { "label": "sierra_leone", "value": "Sierra Leone" },
    { "label": "singapore", "value": "Singapore" },
    { "label": "sint_maarten", "value": "Sint Maarten" },
    { "label": "slovakia", "value": "Slovakia" },
    { "label": "slovenia", "value": "Slovenia" },
    { "label": "solomon_islands", "value": "Solomon Islands" },
    { "label": "somalia", "value": "Somalia" },
    { "label": "south_africa", "value": "South Africa" },
    { "label": "south_georgia", "value": "South Georgia" },
    { "label": "south_sudan", "value": "South Sudan" },
    { "label": "spain", "value": "Spain" },
    { "label": "sri_lanka", "value": "Sri Lanka" },
    { "label": "sudan", "value": "Sudan" },
    { "label": "suriname", "value": "Suriname" },
    { "label": "svalbard_and_jan mayen", "value": "Svalbard and Jan Mayen" },
    { "label": "swaziland", "value": "Swaziland" },
    { "label": "sweden", "value": "Sweden" },
    { "label": "switzerland", "value": "Switzerland" },
    { "label": "syrian_arab_republic", "value": "Syrian Arab Republic" },
    { "label": "taiwan", "value": "Taiwan" },
    { "label": "tajikistan", "value": "Tajikistan" },
    { "label": "tanzania", "value": "Tanzania" },
    { "label": "thailand", "value": "Thailand" },
    { "label": "timor_leste", "value": "Timor Leste" },
    { "label": "togo", "value": "Togo" },
    { "label": "tokelau", "value": "Tokelau" },
    { "label": "tonga", "value": "Tonga" },
    { "label": "trinidad_and_tobago", "value": "Trinidad and Tobago" },
    { "label": "tunisia", "value": "Tunisia" },
    { "label": "turkey", "value": "Turkey" },
    { "label": "turkmenistan", "value": "Turkmenistan" },
    { "label": "turks_and_caicos islands", "value": "Turks and Caicos Islands" },
    { "label": "tuvalu", "value": "Tuvalu" },
    { "label": "uganda", "value": "Uganda" },
    { "label": "ukraine", "value": "Ukraine" },
    { "label": "united_arab_emirates", "value": "United Arab Emirates" },
    { "label": "united_kingdom", "value": "United Kingdom" },
    { "label": "united_states_of america","othername":["United States of America", "United States","USA","US", "America", "U.S.A"], "value": "United States of America" },
    { "label": "uruguay", "value": "Uruguay" },
    { "label": "uzbekistan", "value": "Uzbekistan" },
    { "label": "vanuatu", "value": "Vanuatu" },
    { "label": "venezuela", "value": "Venezuela" },
    { "label": "viet_nam", "value": "Viet Nam" },
    { "label": "virgin_islands", "value": "Virgin Islands" },
    { "label": "wallis_and_futuna", "value": "Wallis and Futuna" },
    { "label": "western_sahara", "value": "Western Sahara" },
    { "label": "yemen", "value": "Yemen" },
    { "label": "zambia", "value": "Zambia" },
    { "label": "zimbabwe", "value": "Zimbabwe" }
  ];