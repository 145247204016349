import { useEffect, } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import AddSoundSubCategory from "./subcategories/AddSoundSubCategory";
import AddArticleSubCategory from "./subcategories/AddArticleSubCategory";
import AddRelaxationSubCategory from "./subcategories/AddRelaxationSubCategory";
import AddStorySubCategory from "./subcategories/AddStorySubCategory";
import AddFeaturedMixSubCategory from "./subcategories/AddFeaturedMixSubCategory";

const SubCategoryManagment = () => {

  useEffect(() => {
    document.title = "Relax Scofa | Category Management";
  }, []);

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">Sub Category</h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <Tabs
                defaultActiveKey="sounds"
                id="uncontrolled-tab-example"
                className="customTabs mb-4"
              >
                <Tab title="Sounds" eventKey={"sounds"}>
                  <AddSoundSubCategory />
                </Tab>
                <Tab title="Relax & Rest" eventKey={"relaxations"}>
                  <AddRelaxationSubCategory />
                </Tab>
                <Tab title="Stories" eventKey={"story"}>
                  <AddStorySubCategory />
                </Tab>
                <Tab title="Articles" eventKey={"articles"}>
                  <AddArticleSubCategory />
                </Tab>
                <Tab title="Featured Mixes" eventKey={"featured"}>
                  <AddFeaturedMixSubCategory />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default SubCategoryManagment;