import React, { useContext, useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { Link } from 'react-router-dom'
import CustomFilters from '../../Components/CustomFilters'
import CustomTable from '../../Components/CustomTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEllipsisV, faEye, faTimes } from '@fortawesome/free-solid-svg-icons'
import CustomPagination from '../../Components/CustomPagination'
import { Dropdown } from 'react-bootstrap'
import { manageRoleData } from '../../Config/PermissionData'
import BASEURL from '../../Config/global'
import axios from 'axios'
import CustomLoader from '../../Components/CustomLoader'
import { errorModal, questionModal, successModal } from '../../Components/CustomModal'
import { disabledButtonByPermission } from '../../Components/Permissions'
import { PermissionsContext } from '../../Layout/PermissionContext'

const RolesManagement = () => {
    const [data, setData] = useState();
    const [offset, setOffset] = useState(0);
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [filterSearchValue, setFilterSearchValue] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [loader, setLoader] = useState(false);
    const [enteries, setEnteries] = useState(10);
    const { permissions } = useContext(PermissionsContext)

    const rolesHeader = [
        {
            key: "id",
            title: "S.No",
        },
        {
            key: "role",
            title: "Role",
        },
        {
          key: "defaultRole",
          title: "Default Role",
      },
        {
            key: "actions",
            title: "Actions",
        },
    ];

  useEffect(() => {
    fetchData()
  }, [enteries, filterSearchValue, dateTo, offset]);
  const fetchData = async () => {
    try {
      setLoader(true)
      const response = await axios.get(
        `${BASEURL}/api/permissions/userrolelist/?limit=${enteries}&offset=${offset}&start_date=${dateFrom}&end_date=${dateTo}&search=${filterSearchValue}`
      );
      if (response.data.error != true) {
        setData(response.data.data);
        setTotalCount(response.data.count);
      } else {
        console.log(response.data.message);
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.error(error);
    }
  }
  const handleDeleteRole = async (id) => {
    let config = {
      method: 'delete',
      url: `${BASEURL}/api/permissions/userrolelist/${id}`,
      headers: {}
    };
    try {
    const response = await axios.request(config)
      console.log("response", response.error)
      if (!response?.data?.error) {
        successModal.fire({
          text: "Role deleted successfully",
          confirmButtonText: "Continue",
        });
        setData(data.filter(f => f.id !== id))
      } else {
        errorModal.fire({
          text: "Unable to delete this Role",
          confirmButtonText: "Continue",
        })
      }
    } catch (err) {
      // setLoadingData(false)
      console.log(err);
    }
  }
  const handleSubmit = (id) => {
    // event.preventDefault();
    questionModal
      .fire({
        title: "Do you want to delete this role?",
        confirmButtonText: "Delete",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await handleDeleteRole(id);
        }
      });
  };

  if(!data){
    return <CustomLoader />
  }
  const modulesWisePermission = (condition) =>{
    if(!permissions){
      return 'd-none'
    }
    return disabledButtonByPermission(permissions, condition)
  }
  const handleChangeEntries = (no) => {
    setOffset(0)
    setEnteries(no)
  }
    return (
        <DashboardLayout>
            <div className="container-fluid">
                <div className="row mb-3">
                    <div className="col-lg-8 mb-2">
                        <h2 className="mainTitle">Roles </h2>
                    </div>
                    <div className="col-lg-4 text-end mb-2">
                        <Link
                            to={"/roles-management/add-role"}
                            className={`customButton primaryButton ${modulesWisePermission('can_add')}`}
                        >
                            Add Roles
                        </Link>
                    </div>
                </div>
                <div className="row mb-3">
            <div className="col-12">
              <CustomFilters
                enteries={enteries}
                totalCount={totalCount}
                entriesFilter={true}
                setEnteries={handleChangeEntries}
                filterSearch={true}
                filterSearchValue={filterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12">
              <CustomTable headers={rolesHeader}>
                <tbody>
                  {data &&
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.actual_roles ? "Yes" : "No"}</td>
                        <td>
                          <Dropdown className="tableDropdown">
                            <Dropdown.Toggle
                              variant="transparent"
                              className="notButton classicToggle"
                            >
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              align="end"
                              className="tableDropdownMenu"
                            >
                              <Link
                                to={`/roles-management/view-roles/?role_id=${item.id}&role_name=${item.name}`}
                                className={`tableAction ${modulesWisePermission('can_edit')}`}
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="tableActionIcon"
                                />
                                Edit
                              </Link>
                              <button
                                className={`tableAction ${modulesWisePermission('can_delete')}`}
                                onClick={() => {
                                  handleSubmit(item.id);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="tableActionIcon"
                                />
                                Delete
                              </button>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </CustomTable>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomPagination
                enteries={enteries}
                totalCount={totalCount}
                setCurrentPage={setOffset}
                currentPage={offset}
              />
            </div>
          </div>
            </div>
        </DashboardLayout>
    )
}

export default RolesManagement