import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomLoader from "../../Components/CustomLoader";
import "./style.css";
import BASEURL from "../../Config/global";
import moment from "moment";

const SubscriptionDetails = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${BASEURL}/api/user/packages/${id}`);
        setFormData(response.data.data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);



  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12 mb-2">
              <h2 className="mainTitle">
                <BackButton />
                Subscription Detail
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="row g-0 px-3">
                
                <div className="col-12 mb-2">
                  <h2 className="mainTitle mb-4">{formData?.title}</h2>
                  <p className="mb-0"><span className='lightColor'>Description: </span>{formData?.description}</p>
                </div>
                <div className="col-12">
                  <hr className="my-3" />
                </div>
                <div className="col-lg-3 mb-2">
                  <small className="lightColor mb-2">Price</small>
                  <p>{formData?.price}</p>
                </div>
                
                <div className="col-lg-3 mb-2">
                  <small className="lightColor mb-2">Subscription Type:</small>
                  <p className="text-capitalize">{formData?.interval ? `${formData?.interval}ly` : ""}</p>
                </div>
                <div className="col-lg-3 mb-2">
                <small className="lightColor mb-2">Created Date</small>
                  <p>{moment(formData.created).format('MM/DD/YYYY')}</p>
                </div>
                <div className="col-lg-3 mb-2">
                <small className="lightColor mb-2">Modified Date</small>
                  <p>{moment(formData.updated).format('MM/DD/YYYY')}</p>
                </div>
                
                

                <div className="col-lg-3 mb-5">
                  <small className="lightColor mb-2">Primary Info Slider</small>
                  <p>{formData?.primary_package_user?.name}</p>
                </div>

                

                <div className="col-lg-3 mb-5">
                  <small className="lightColor mb-2">Household Info Slider</small>
                  <p>{formData?.household_package_user?.name}</p>
                </div>
               

                <div className="col-lg-3 mb-5">
                  <small className="lightColor mb-2">Subscription Asset</small>
                  <p>{formData?.promo_upload_asset?.title}</p>
                </div>
                <div className="col-lg-3 mb-5">
                  <small className="lightColor mb-2">Propagation</small>
                  <p>{formData?.is_propagation_allowed ? "Enable" : "Disable"}</p>
                </div>  
              </div>
              
            </div>
            {
              formData?.is_propagation_allowed ?

                <>
                  <div className="col-12">
                    <div className="px-3">
                      <h2 className="mainTitle mb-4">{formData?.propagation_title}</h2>
                      <p className="mb-1"><span className='lightColor'>Propagation Description: </span>{formData?.propagation_description}</p>
                      <p className="mb-0"><span className='lightColor'>Propagation Promo Description: </span>{formData?.propagation_code_description}</p>
                    </div>
                  </div>

                  <div className="col-12">
                    <hr className="my-4" />
                  </div>

                  <div className="col-12">
                    <div className="row g-0 px-3">
                      {/* <div className="col-lg mb-2">
                            <small className="lightColor mb-2">Code</small>
                            <p>{formData?.propagation_code}</p>
                          </div> */}

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Price</small>
                        <p>{formData?.propagation_price}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Expires</small>
                        <p>{(formData?.propagation_is_expiry_unlimited) ? 'Unlimited' : moment(formData?.propagation_expiry_date).format('MM-DD-YYYY')}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Created On</small>
                        <p>{moment(formData?.propagation_created).format('MM-DD-YYYY')}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Updated at</small>
                        <p>{moment(formData?.propagation_updated).format('MM-DD-YYYY')}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Subscription Type</small>
                        <p className='text-capitalize'>{formData?.propagation_subscription_type}ly</p>
                      </div>

                    </div>
                  </div>

                  <div className="col-12"></div>
                  <div className="col-12">
                    <div className="row g-0 px-3">

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">{(formData?.propagation_duration_type === 'trial_period') ? 'Trial Period' : 'Term in days'}</small>
                        <p>{formData?.propagation_duration_type === 'trial_period' ? formData?.propagation_trial_period : formData?.propagation_term_in_days}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Primary Info Slider</small>
                        <p>{formData?.propagation_primary_promo_user?.name}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Household Info Slider</small>
                        <p>{formData?.propagation_household_promo_user?.name}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Propagation</small>
                        <p>{formData?.propagation_is_propagation_allowed ? "Enable" : "Disable"}</p>
                      </div>

                      <div className="col-lg mb-2">
                        <small className="lightColor mb-2">Propagation Asset</small>
                        <p>{formData?.promo_propagation_asset?.title}</p>
                      </div>

                    </div>
                  </div>

                </>

                :
                <></>
            }

          </div>

        </div>
      </DashboardLayout>
    </>
  );
};
export default SubscriptionDetails;
