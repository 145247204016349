import { useState, useEffect, useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";

import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faEye,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";

import CustomTable from "../../Components/CustomTable";
import { placeholderImage } from "../../Assets/images";

import "./style.css";
import { questionModal, successModal } from "../../Components/CustomModal";
import CustomFilters from "../../Components/CustomFilters";
import BASEURL from "../../Config/global";
import CustomPagination from "../../Components/CustomPagination";
import CustomLoader from "../../Components/CustomLoader";
import { disabledButtonByPermission } from "../../Components/Permissions";
import { PermissionsContext } from "../../Layout/PermissionContext";
import moment from "moment";

const CouponManagement = () => {
  const [data, setData] = useState([]);
  const { permissions } = useContext(PermissionsContext)

  const [enteries, setEnteries] = useState(10);
  const [offset, setOffset] = useState(0);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const [sort, setSort] = useState("all");
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);


  useEffect(() => {
    if (currentPage != undefined) {
      setOffset(currentPage);
    }
  }, [currentPage])

  const modulesWisePermission = (condition) => {
    if (!permissions) {
      return 'd-none'
    }
    return disabledButtonByPermission(permissions, condition)
  }
  useEffect(() => {
    document.title = "Relax Scofa | Promo Management";
  }, []);

  useEffect(() => {
    fetchData();
  }, [enteries, dateTo, filterSearchValue, offset]);

  async function fetchData() {
    try {
      const response = await axios.get(
        `${BASEURL}/api/user/coupon?limit=${enteries}&offset=${offset}&start_date=${dateFrom}&end_date=${dateTo}&search=${filterSearchValue}`
      );
      if (response.data.error != true) {
        console.log(response.data.data);
        setData(response.data.data);
        setTotalCount(response.data.count);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // useEffect(() => {
  //   if (filterSearchValue.length > 0) {
  //     const filteredData = data.filter((item) => item.title.toLowerCase().includes(filterSearchValue));
  //     setData(filteredData)
  //   } else {
  //     fetchData();
  //   }
  // }, [filterSearchValue]);

  const deleteArticle = async (id) => {

    questionModal
      .fire({
        title: `Do you want to delete Promo?`,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteArticleAction(id);
        }
      });



  };

  const deleteArticleAction = async (id) => {
    setLoader(true);

    try {
      const response = await axios.delete(`${BASEURL}/api/user/coupon/${id}/`);
      if (response.data.error === false) {
        successModal.fire({
          text: "Promo Deleted Successfully",
          confirmButtonText: "Continue",
        });
        setData(data.filter((item) => item.id !== id));
        setLoader(false);
        Navigate("/promo-management");
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const couponsHeader = [
    {
      key: "id",
      title: "S.No",
    },
    {
      key: "title",
      title: "Title",
    },
    {
      key: "primary_user_count",
      title: "Primary Count",
    },
    {
      key: "household_user_count",
      title: "Household Count",
    },
    {
      key: "expires",
      title: "Expires",
    },
    {
      key: "code",
      title: "Code",
    },
    {
      key: "allowed_uses",
      title: "Allowed Uses",
    },
    {
      key: "current_uses",
      title: "Current Uses",
    },
    {
      key: "price",
      title: "Price",
    },
    {
      key: "trialperiod",
      title: "Trial period",
    },
    {
      key: "termsindays",
      title: "Terms in days",
    },
    {
      key: "subscriptiontype",
      title: "Subscription type",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];

  const sortingValues = [
    {
      value: "all",
      text: "All",
    },
    {
      value: "status",
      text: "Status",
    },
    {
      value: "registered",
      text: "Registered Date",
    },
  ];

  const handleChangeEntries = (no) => {
    setOffset(0)
    setEnteries(no)
  }
  
  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-lg-8 mb-2">
              <h2 className="mainTitle">Promos </h2>
            </div>
            <div className="col-lg-4 text-end mb-2">
              <Link
                to={"/promo-management/add-promo"}
                className={`customButton primaryButton ${modulesWisePermission('can_add')}`}
              >
                Add Promo
              </Link>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomFilters
                enteries={enteries}
                totalCount={totalCount}
                entriesFilter={true}
                setEnteries={handleChangeEntries}
                filterSearch={true}
                filterSearchValue={filterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
                // dateFilter={true}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
              // isSearchDropdown={couponsHeader}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomTable headers={couponsHeader}>
                <tbody>
                  {data &&
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        <td>{item.primary_user_count}</td>
                        <td>{item.house_hold_user_count}</td>
                        <td>{(item.expiry_date === "undefined" || item.expiry_date === null) ? "Unlimited" : moment(item.expiry_date).format('MM-DD-YYYY')}</td>
                        <td className="text-uppercase">{item.code}</td>
                        {/* allowed uses */}
                        <td>{Number(item?.number_of_uses_allowed)>0?item?.number_of_uses_allowed:'Unlimited'}</td>
                        {/* current uses */}
                        <td>
                          {
                            item?.current_uses && <Link
                              to={`/reportings/promo/${item.id}`}
                              // className={`tableAction`}
                              style={{ textDecoration: 'none', color: 'white' }}
                              target="_blank"
                            >
                              {item?.current_uses}
                            </Link>
                          }
                        </td>
                        <td>USD {item.price}</td>
                        <td>{(!item.trial_period || item.trial_period === 'undefined' || Number(item.trial_period) === 0) ? '-' : item.trial_period}</td>
                        <td>{!item.term_in_days || item.term_in_days === 'undefined' || Number(item.term_in_days) === 0 ? '-' : item.term_in_days}</td>
                        <td>{`${item.subscription_type}ly`}</td>
                        <td>
                          <Dropdown className="tableDropdown">
                            <Dropdown.Toggle
                              variant="transparent"
                              className="notButton classicToggle"
                            >
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              align="end"
                              className="tableDropdownMenu"
                            >
                              <Link
                                to={`/promo-management/edit-promo/${item.id}`}
                                className={`tableAction ${modulesWisePermission('can_edit')}`}
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="tableActionIcon"
                                />
                                Edit
                              </Link>
                              <Link
                                to={`/promo-management/promo-detail/${item.id}`}
                                className={`tableAction ${modulesWisePermission('can_view')}`}
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="tableActionIcon"
                                />
                                View
                              </Link>
                              <button
                                className={`tableAction ${modulesWisePermission('can_delete')}`}
                                onClick={() => {
                                  deleteArticle(item.id, item.title);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="tableActionIcon"
                                />
                                Delete
                              </button>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </CustomTable>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
            <CustomPagination
                enteries={enteries}
                totalCount={totalCount}
                setCurrentPage={setOffset}
                currentPage={offset}
              />
            </div>
          </div>
        </div>
      </DashboardLayout>
      {loader && <CustomLoader />}
    </>
  );
};
export default CouponManagement;
