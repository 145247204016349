import React, { useEffect, useState, useRef } from 'react';
import './style.css';
import { COUNTRIES_MULTISELECT } from './data'
import Select from 'react-select'


const CountrySelector = ({ value, name, handleChange, inputClass }) => {
  const [selectedCountry, setSelectedCountry] = useState([]);
  useEffect(() => {
    if (value === "") {
      return
    } else {
      setSelectedCountry(value.map(item => ({ label: item, value: item, othername: item?.othername })))
    }
  }, [])

  return (
    <div>
      <p className="mainLabel my-2">{name}</p>
      <Select
        value={selectedCountry}
        isMulti
        name="colors"
        options={COUNTRIES_MULTISELECT.map(item => ({ label: item.value, value: item.value, othername: item?.othername }))}
        className="basic-multi-select"
        classNamePrefix="select"
        filterOption={(option, inputValue) => {
          if (option.value?.toLowerCase().includes(inputValue?.toLowerCase())) {
            return true;
          } else if (option.data?.othername) {
            const result = option.data.othername.some(f => f?.toLowerCase().includes(inputValue.toLowerCase()))
            return result
          } else {
            return false;
          }
        }}
        onChange={(values) => {
          setSelectedCountry(values)
          handleChange(values.map(item => item.value).join(","))
        }}
      />
    </div>
  );
}
export default CountrySelector;


