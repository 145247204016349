import React, { useEffect, useState } from 'react'
import CustomButton from '../../CustomButton';
import CustomInput from '../../CustomInput';
import BackButton from '../../BackButton';
import axios from 'axios';
import { questionModal, successModal } from '../../CustomModal';
import { Modal } from 'react-bootstrap';
import BASEURL, { FE_BASE_URL } from '../../../Config/global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faTimes } from '@fortawesome/free-solid-svg-icons';
import "./style.css"
import moment from 'moment';
import { useNavigate } from 'react-router';
import CustomLoader from '../../CustomLoader';
import MultiCountrySelect from '../../MultiCountrySelect';


const PromoForm = ({ config }) => {

    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [sliderList, setSliderList] = useState([]);
    const [assetData, setAssetData] = useState([]);
    const [minDate, setMinDate] = useState(new Date());
    const [loginError, setLoginError] = useState({ error: false, text: "" });
    const [showModal, setShowModal] = useState(false);
    const [copiedModal, setCopiedModal] = useState(false);
    const [loader, setLoader] = useState(false);
    useEffect(()=>{
        fetchAssetData()
    },[])
    useEffect(() => {
        // set form data
        if (config?.initial_data) {
            setFormData(config?.initial_data);
        }

        // Fetch promo data by using ID (only for edit)
        if (config.main_config.form_type === "Edit") {
            if (config.main_config.id != null) {
                getDataByApi(config.main_config.id);
            } else {
                console.log("Did not find id")
            }
        } else {
            setFormData(config?.initial_data);
        }

        // Fetch slider list from API
        getSliderByApi(config?.slider_api_config);

        // Set today date
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        setMinDate(year + '-' + month + '-' + day);

    }, [config])

    async function fetchAssetData() {
        try {

            const response = await axios.get(
                `${BASEURL}/api/user/upload_asset/`
            );
            if (response.data.error !== true) {
                console.log(response.data.data);
                setAssetData(response.data.data);
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getDataByApi = async (id) => {
        try {
            const response = await axios.get(config.api_config.url + '/' + id);
            if (!response?.data?.error) {
                let obj = response?.data?.data;
                let propagatedObj = response?.data?.data?.propated_coupon_details;
                obj = { ...obj, ...propagatedObj, ...config.initial_data }
                setFormData(obj);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getSliderByApi = async (config) => {
        try {
            const response = await axios.get(config.url);
            if (!response?.data?.error) {
                setSliderList(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleChange = (event) => {
        setLoginError({ error: false, text: "" });
        const { name, value } = event.target;
        if (name === 'code' || name === 'trial_period' || name === 'term_in_days' || name === 'number_of_uses_allowed' || name === 'propagation_code' || name === 'propagation_trial_period' || name === 'propagation_term_in_days' || name === 'price' || name === 'propagation_price') {

            if (name === 'code' || name === 'propagation_code') {
                if (value.length <= 20) {
                    setFormData({ ...formData, [name]: value });
                } else {
                    setLoginError({ error: true, text: "Promo code should be less than 20 digits." });
                }
            }

            if (value >= 0) {
                if (name === 'trial_period' || name === 'term_in_days' || name === 'propagation_trial_period' || name === 'propagation_term_in_days') {
                    if (value.length <= 4) {
                        setFormData({ ...formData, [name]: value });
                    } else {
                        setLoginError({ error: true, text: `${name === "trial_period" ? "Trial Period" : "Term in Days"} should be less than 4 digits.` });
                    }
                }

                if (name === 'number_of_uses_allowed') {
                    if (value.length <= 6) {
                        setFormData({ ...formData, [name]: value });
                    } else {
                        setLoginError({ error: true, text: `Uses Allowed should be less than 6 digits.` });
                    }
                }

                if (name === 'price' || name === 'propagation_price') {
                    setFormData({ ...formData, [name]: value });
                }
            }

        } else {
            console.log("chec", [name], value)
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoginError({ error: false, text: "" });
        const { promo_propagation_asset, promo_asset } = formData;
        if (!promo_asset?.id || isNaN(promo_asset?.id)) {
            setLoginError({ error: true, text: "Please Fill All the Required Fields" });
            return
        }
        if (formData?.is_propagation_allowed) {
            if (!promo_propagation_asset?.id || isNaN(promo_propagation_asset?.id)) {
                setLoginError({ error: true, text: "Please Fill All the Required Fields" });
                return
            }
        }
        // if (Number(formData?.price) === 0) {
        //     setLoginError({ error: true, text: "Price cannot be zero" });
        //     return
        // }
        if (formData?.countries === "" || !formData?.countries) {
            setLoginError({ error: true, text: "Please Fill Countries Field" });
            return
        }
        if (formData?.is_propagation_allowed && Number(formData?.propagation_price) === 0) {
            setLoginError({ error: true, text: "Propagation Price cannot be zero" });
            return
        }
        
        if (formData.number_of_uses_allowed && formData.current_uses) {
            
            if (Number(formData.number_of_uses_allowed) > 0 && Number(formData.number_of_uses_allowed) < Number(formData.current_uses) ) {
                setLoginError({ error: true, text: "Uses Allowed can not be less than Current Uses" });
                return
            }
        }
        if ((formData.expiry_date && formData.expiry_date != '') || formData.is_expiry_unlimited) {
            if (formData.is_propagation_allowed) {
                if ((formData.propagation_expiry_date && formData.propagation_expiry_date != '') || formData.propagation_is_expiry_unlimited) {
                    const isEdit = config.main_config.form_type
                    questionModal.fire({
                        title: `Do you want to ${isEdit === "Edit" ? "Update" : config.main_config.form_type} this Promo?`,
                        confirmButtonText: `${isEdit === "Edit" ? "Update":config.main_config.form_type}`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setLoader(true);
                            postDataByApi();
                        }
                    });
                } else {
                    setLoginError({ error: true, text: "Please Fill All the Required Fields" });
                }
            } else {
                const isEdit = config.main_config.form_type

                questionModal.fire({
                    title: `Do you want to ${isEdit === "Edit" ? "Update" : config.main_config.form_type} this Promo?`,
                    confirmButtonText: `${isEdit === "Edit" ? "Update":config.main_config.form_type}`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        setLoader(true);
                        postDataByApi();
                    }
                });
            }
        } else {
            setLoginError({ error: true, text: "Please Fill All the Required Fields" });
        }
    };

    const postDataByApi = async () => {
        try {
            const body = {
                "title": formData.title,
                "description": formData.description,
                "code_description": formData.code_description,
                "code": formData.code,
                "price": Number(formData.price),
                "expiry_date": formData.is_expiry_unlimited ? null : formData.expiry_date,
                "is_expiry_unlimited": formData.is_expiry_unlimited,
                "subscription_type": formData.subscription_type,
                "duration_type": formData.duration_type,
                "mtag": formData.mtag,
                "active_status": formData.active_status,
                "trial_period": Number(formData.trial_period),
                "term_in_days": Number(formData.term_in_days),
                "number_of_uses_allowed": Number(formData.number_of_uses_allowed),
                "primary_promo_user": Number(formData?.primary_promo_user?.id),
                "household_promo_user": Number(formData?.household_promo_user?.id),
                "is_propagation_allowed": formData.is_propagation_allowed,
                "propagation_title": formData.propagation_title,
                "propagation_description": formData.propagation_description,
                "propagation_code_description": formData.propagation_code_description,
                "propagation_code": formData.propagation_code,
                "propagation_price": Number(formData.propagation_price),
                "propagation_expiry_date": formData.propagation_is_expiry_unlimited ? null : formData.propagation_expiry_date,
                "propagation_is_expiry_unlimited": formData.propagation_is_expiry_unlimited,
                "propagation_subscription_type": formData.propagation_subscription_type,
                "propagation_duration_type": formData.propagation_duration_type,
                "propagation_trial_period": Number(formData.propagation_trial_period),
                "propagation_term_in_days": Number(formData.propagation_term_in_days),
                "propagation_primary_promo_user": Number(formData?.propagation_primary_promo_user?.id),
                "propagation_household_promo_user": Number(formData?.propagation_household_promo_user?.id),
                "promo_asset": formData?.promo_asset?.id,
                "promo_propagation_asset": formData?.promo_propagation_asset?.id,
                "propagation_is_propagation_allowed":formData?.propagation_is_propagation_allowed,
                "countries":formData?.countries
            }
            let response;
            if (config.main_config.form_type === "Edit") {
                response = await axios.patch(`${BASEURL}/api/user/coupon/${config.main_config.id}/`, body);
            } else {
                response = await axios.post(`${BASEURL}/api/user/coupon`, body);
            }
            if (!response.data.error) {
                const isEdit = config.main_config.form_type
                successModal.fire({
                    text: `Promo ${isEdit === "Edit" ? "Update":config.main_config.form_type} Successfully`,
                    confirmButtonText: "Continue",
                });
                navigate("/promo-management");
                setLoader(false);
            } else {
                setLoader(false);
                setLoginError({ error: true, text: response?.data?.message ? response.data.message : "An error occurred. Please try again later." });
            }
        } catch (error) {
            setLoader(false);
            setLoginError({ error: true, text: "An error occurred. Please try again later.", });
        }
    };

    const handleCopy = (url) => {
        navigator.clipboard.writeText(url);
        setShowModal(false);
        setCopiedModal(true);
        setTimeout(() => {
            setCopiedModal(false);
        }, 1500);
    }

    return (
        <div>

            {
                (formData.hasOwnProperty('title'))
                    ?
                    <div className="container-fluid">

                        <div className="row mb-3">
                            <div className="col-12 mb-2">
                                <h2 className="mainTitle">
                                    <BackButton />
                                    {config.main_config.title || 'Promo'}
                                </h2>
                            </div>
                        </div>

                        <br />
                        <br />

                        <form onSubmit={handleSubmit}>

                            <div className="row mb-3">

                               

                                <div className="col-lg-3 mb-2">
                                    <CustomInput
                                        label="Title"
                                        labelClass="mainLabel"
                                        required
                                        requiredHint="Subscription Page"
                                        type="text"
                                        name="title"
                                        value={formData.title || ""}
                                        placeholder="Enter Title"
                                        inputClass="mainInput"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-lg mb-2">
                                    <CustomInput
                                        label="Description"
                                        labelClass="mainLabel"
                                        required
                                        requiredHint="Subscription Page"
                                        type="text"
                                        name="description"
                                        value={formData?.description || ""}
                                        placeholder="Enter Description"
                                        inputClass="mainInput"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-lg mb-2">
                                    <CustomInput
                                        label="Code Description"
                                        labelClass="mainLabel"
                                        requiredHint="Promo Code Popup"
                                        type="text"
                                        name="code_description"
                                        value={formData.code_description || ""}
                                        placeholder="Enter Code Description"
                                        inputClass="mainInput"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="col-12"></div>

                                <br />
                                <br />
                                <br />

                                <div className="col-12"></div>

                                <div className="col-lg mb-2">
                                    <CustomInput
                                        label="Code"
                                        labelClass="mainLabel"
                                        required
                                        type="text"
                                        name="code"
                                        value={formData?.code || ""}
                                        placeholder="Enter Code"
                                        inputClass="mainInput"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-lg mb-2">
                                    <CustomInput
                                        label="Price"
                                        labelClass="mainLabel"
                                        required
                                        type="number"
                                        name="price"
                                        value={formData.price || ""}
                                        placeholder="Enter Price"
                                        inputClass="mainInput"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-lg mb-2">
                                    <div className="position-relative coupon-expires">
                                        <label className="mainLabel">Expires <span style={{ fontSize: '10px' }}>(Check for unlimited)</span>*</label>
                                        <CustomInput
                                            type="date"
                                            name="expiry_date"
                                            value={formData.expiry_date || ""}
                                            inputClass="mainInput"
                                            onChange={handleChange}
                                            min={minDate}
                                        />
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={formData.is_expiry_unlimited}
                                            onChange={(e) => {
                                                setFormData({ ...formData, 'is_expiry_unlimited': e.target.checked });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg mb-2">
                                    <p className="mainLabel mb-4">Subscription Type</p>
                                    <label className="secondaryText me-3">
                                        <input
                                            type="radio"
                                            name="subscription_type"
                                            id="month"
                                            value="month"
                                            checked={formData.subscription_type === 'month'}
                                            className="me-1"
                                            onChange={handleChange}
                                        />
                                        Monthly
                                    </label>
                                    <label className="secondaryText">
                                        <input
                                            type="radio"
                                            name="subscription_type"
                                            id="year"
                                            value="year"
                                            checked={formData.subscription_type === 'year'}
                                            className="me-1"
                                            onChange={handleChange}
                                        />
                                        Yearly
                                    </label>
                                </div>

                                <div className="col-12"></div>

                                {
                                    config.main_config.form_type === "Edit" ?
                                        <>
                                            <div className="col-12"></div>

                                            <br />
                                            <br />
                                            <br />

                                            <div className="col-12"></div>

                                            {
                                                config.main_config.form_type === "Edit" ?
                                                    <>
                                                        <div className="col-lg-3 mb-2">
                                                            <p className="mainLabel mb-4">Active Status</p>
                                                            <label className="secondaryText me-3">
                                                                <input
                                                                    type="radio"
                                                                    // name="is_propagation_allowed"
                                                                    // value={"Yes"}
                                                                    // className="me-1"
                                                                    // onChange={setpropagationAllowed}
                                                                    name="active_status"
                                                                    value={true}
                                                                    id="active_status"
                                                                    checked={formData.active_status}
                                                                    onChange={(event) => {
                                                                        const { name, value } = event.target;
                                                                        setFormData({ ...formData, [name]: true })
                                                                    }}
                                                                />
                                                                <label htmlFor="active_status" className="ms-2">Active</label>
                                                            </label>
                                                            <label className="secondaryText">
                                                                <input
                                                                    type="radio"
                                                                    name="active_status"
                                                                    value={false}
                                                                    id="unactive_status"
                                                                    checked={!formData.active_status}
                                                                    onChange={(event) => {
                                                                        const { name, value } = event.target;
                                                                        setFormData({ ...formData, [name]: false })
                                                                    }}
                                                                />
                                                                <label htmlFor="unactive_status" className="ms-2">Inactive</label>
                                                            </label>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }



                                            <div className="col-lg-3 mb-2">
                                                <CustomInput
                                                    label="Created On"
                                                    labelClass="mainLabel"
                                                    type="text"
                                                    name="created"
                                                    value={moment(formData?.created).format('MM/DD/YYYY') || ""}
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className="col-lg-3 mb-2">
                                                <CustomInput
                                                    label="Updated at"
                                                    labelClass="mainLabel"
                                                    type="text"
                                                    name="updated"
                                                    value={moment(formData?.updated).format('MM/DD/YYYY') || ""}
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className="col-lg-3 mb-2">
                                                <p className="mainLabel mb-2">Copy URL</p>
                                                <div className="copy-text">
                                                    <button type='button' onClick={() => formData.code.length > 0 && setShowModal(true)}>
                                                        <FontAwesomeIcon
                                                            icon={faClone}
                                                        />
                                                    </button>
                                                </div>
                                            </div>

                                        </>
                                        :
                                        <></>
                                }

                                <div className="col-12"></div>

                                <br />
                                <br />
                                <br />

                                <div className="col-12"></div>

                                <div className="col-lg-3 mb-2">
                                    <p className="mainLabel mb-4">Select Duration Type</p>
                                    <label className="secondaryText me-3">
                                        <input
                                            type="radio"
                                            name="duration_type"
                                            value="trial_period"
                                            id="trial_period"
                                            checked={formData.duration_type === 'trial_period'}
                                            onChange={(event) => {
                                                const { name, value } = event.target;
                                                setFormData({ ...formData, [name]: value, 'term_in_days': '' })
                                            }}
                                        />
                                        <label htmlFor="trial_period" className="ms-2">Trial Period</label>
                                    </label>
                                    <label className="secondaryText">
                                        <input
                                            type="radio"
                                            name="duration_type"
                                            value="term_in_days"
                                            id="term_in_days"
                                            checked={formData.duration_type === 'term_in_days'}
                                            onChange={(event) => {
                                                const { name, value } = event.target;
                                                setFormData({ ...formData, [name]: value, 'trial_period': '' })
                                            }}
                                        />
                                        <label htmlFor="term_in_days" className="ms-2">Term in Days</label>
                                    </label>
                                </div>
                                {
                                    formData.duration_type === 'trial_period' ?
                                        <>
                                            <div className="col-lg-3 mb-2">
                                                <CustomInput
                                                    label="Trial Period"
                                                    labelClass="mainLabel"
                                                    type="number"
                                                    name="trial_period"
                                                    value={formData.trial_period || ""}
                                                    placeholder="Trial Period"
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="col-lg-3 mb-2">
                                                <CustomInput
                                                    label="Term in Days"
                                                    labelClass="mainLabel"
                                                    type="number"
                                                    name="term_in_days"
                                                    value={formData.term_in_days || ""}
                                                    placeholder="Term in Days"
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </>
                                }
                                <div className="col-lg-3 mb-2">
                                    <CustomInput
                                        label="Uses Allowed"
                                        labelClass="mainLabel"
                                        type="number"
                                        name="number_of_uses_allowed"
                                        value={formData.number_of_uses_allowed || ""}
                                        placeholder="Uses Allowed"
                                        inputClass="mainInput"
                                        onChange={handleChange}
                                    />
                                </div>

                                {
                                    config.main_config.form_type === "Edit" ?
                                        <>
                                            <div className="col-lg-3 mb-2">
                                                <CustomInput
                                                    label="Current Uses"
                                                    labelClass="mainLabel"
                                                    type="number"
                                                    name="current_uses"
                                                    value={formData.current_uses || ""}
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                    disabled={true}
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                        </>
                                }

                                {
                                    config.main_config.form_type === "Add" ?
                                        <>

                                            <div className="col-lg-3 mb-2">
                                                <p className="mainLabel mb-2">Copy URL</p>
                                                <div className="copy-text">
                                                    <button type='button' onClick={() => formData.code.length > 0 && setShowModal(true)}>
                                                        <FontAwesomeIcon
                                                            icon={faClone}
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                        </>
                                }

                                <div className="col-12"></div>

                                <br />
                                <br />
                                <br />

                                <div className="col-lg-3 mb-2">
                                    <CustomInput
                                        label="M-tag"
                                        labelClass="mainLabel"
                                        type="text"
                                        name="mtag"
                                        value={formData.mtag || ""}
                                        placeholder="Tag Name"
                                        inputClass="mainInput"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="col-lg-3 mb-2">
                                    <p className="mainLabel">Primary Info Slider</p>
                                    {sliderList && (
                                        <select
                                            name="primary_promo_user"
                                            id="primary_promo_user"
                                            className="mainInput w-100"
                                            value={formData?.primary_promo_user?.id || ""}
                                            onChange={(event) => {
                                                const { name, value } = event.target;
                                                setFormData({
                                                    ...formData, [name]: {
                                                        ...formData[name],
                                                        "id": Number(value)
                                                    }
                                                });
                                            }}
                                        >
                                            <option value={null}> Select </option>
                                            {sliderList.length > 0 && sliderList.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.slider_name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                                <div className="col-lg-3 mb-2">
                                    <p className="mainLabel">Household Info Slider</p>
                                    {sliderList && (
                                        <select
                                            name="household_promo_user"
                                            id="household_promo_user"
                                            className="mainInput w-100"
                                            value={formData?.household_promo_user?.id || ""}
                                            onChange={(event) => {
                                                const { name, value } = event.target;
                                                setFormData({
                                                    ...formData, [name]: {
                                                        ...formData[name],
                                                        "id": Number(value)
                                                    }
                                                });
                                            }}
                                        >
                                            <option value={null}> Select </option>
                                            {sliderList.length > 0 && sliderList.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.slider_name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                                <div className="col-lg-3 mb-2">
                                    <p className="mainLabel">Select Promo Asset</p>
                                    {assetData && (
                                        <select
                                            name="promo_asset"
                                            id="promo_asset"
                                            className="mainInput w-100"
                                            required
                                            value={formData?.promo_asset?.id || ""}
                                            onChange={(event) => {
                                                const { name, value } = event.target;
                                                setFormData({
                                                    ...formData, [name]: {
                                                        ...formData[name],
                                                        "id": Number(value)
                                                    }
                                                });
                                            }}
                                        >
                                            <option value={null}> Select </option>
                                            {assetData.length > 0 && assetData.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                                <div className="col-12 my-3">
                                    <MultiCountrySelect
                                        value={formData.countries ? formData.countries.split(",") : ""}
                                        handleChange={(countries) => {
                                            setFormData({ ...formData, countries })
                                        }}
                                        inputClass={''}
                                        name={'Select Countries'}
                                    />
                                </div>
                                <div className="offset-lg-0 col-lg-3 mb-2">
                                    <p className="mainLabel mb-4">Propagation</p>
                                    <label className="secondaryText me-3">
                                        <input
                                            type="radio"
                                            name="is_propagation_allowed"
                                            value={true}
                                            id="is_propagation_allowed_true"
                                            checked={formData.is_propagation_allowed}
                                            onChange={(event) => {
                                                const { name, value } = event.target;
                                                setFormData({ ...formData, [name]: true })
                                            }}
                                        />
                                        <label htmlFor="is_propagation_allowed_true" className="ms-2">Enable</label>
                                    </label>
                                    <label className="secondaryText">
                                        <input
                                            type="radio"
                                            name="is_propagation_allowed"
                                            value={false}
                                            id="is_propagation_allowed_false"
                                            checked={!formData.is_propagation_allowed}
                                            onChange={(event) => {
                                                const { name, value } = event.target;
                                                setFormData({ ...formData, [name]: false })
                                            }}
                                        />
                                        <label htmlFor="is_propagation_allowed_false" className="ms-2">Disable</label>
                                    </label>
                                </div>

                                <div className="col-12"></div>

                                <br />
                                <br />
                                <br />

                                <div className="col-12"></div>

                                {
                                    formData.is_propagation_allowed ?
                                        <>

                                            <div className="col-12"></div>

                                            <hr />
                                            <br />
                                            <br />
                                            <br />

                                            <div className="col-12"></div>

                                            <div className="col-lg-3 mb-2">
                                                <CustomInput
                                                    label="Propagation Title"
                                                    requiredHint="Subscription Page"
                                                    labelClass="mainLabel"
                                                    required
                                                    type="text"
                                                    name="propagation_title"
                                                    value={formData.propagation_title || ""}
                                                    placeholder="Enter Title"
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-lg mb-2">
                                                <CustomInput
                                                    label="Propagation Description"
                                                    requiredHint="Subscription Page"
                                                    labelClass="mainLabel"
                                                    required
                                                    type="text"
                                                    name="propagation_description"
                                                    value={formData.propagation_description || ""}
                                                    placeholder="Enter Description"
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-lg mb-2">
                                                <CustomInput
                                                    label="Promo Code Description"
                                                    requiredHint="Promo Code Popup"
                                                    labelClass="mainLabel"
                                                    type="text"
                                                    name="propagation_code_description"
                                                    value={formData.propagation_code_description || ""}
                                                    placeholder="Enter Code Description"
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="col-12"></div>

                                            <br />
                                            <br />
                                            <br />

                                            <div className="col-12"></div>

                                            <div className="offset-lg-0 col-lg-3 mb-2">
                                                <p className="mainLabel mb-4">Propagation</p>
                                                <label className="secondaryText me-3">
                                                    <input
                                                        type="radio"
                                                        name="propagation_is_propagation_allowed"
                                                        value={true}
                                                        id="propagation_is_propagation_allowed_true"
                                                        checked={formData.propagation_is_propagation_allowed}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({ ...formData, [name]: true })
                                                        }}
                                                    />
                                                    <label htmlFor="propagation_is_propagation_allowed_true" className="ms-2">Enable</label>
                                                </label>
                                                <label className="secondaryText">
                                                    <input
                                                        type="radio"
                                                        name="propagation_is_propagation_allowed"
                                                        value={false}
                                                        id="propagation_is_propagation_allowed_false"
                                                        checked={!formData.propagation_is_propagation_allowed}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({ ...formData, [name]: false })
                                                        }}
                                                    />
                                                    <label htmlFor="propagation_is_propagation_allowed_false" className="ms-2">Disable</label>
                                                </label>
                                            </div>
                                            <div className="col-lg mb-2">
                                                <CustomInput
                                                    label="Propagation Price"
                                                    labelClass="mainLabel"
                                                    required
                                                    type="number"
                                                    name="propagation_price"
                                                    value={formData.propagation_price || ""}
                                                    placeholder="Enter Price"
                                                    inputClass="mainInput"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-lg mb-2">
                                                <div className="position-relative coupon-expires">
                                                    <label className="mainLabel">Propagation Expires <span style={{ fontSize: '10px' }}>(Check for unlimited)</span>*</label>
                                                    <CustomInput
                                                        type="date"
                                                        name="propagation_expiry_date"
                                                        value={formData.propagation_expiry_date || ""}
                                                        inputClass="mainInput"
                                                        onChange={handleChange}
                                                        min={minDate}
                                                    />
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={formData.propagation_is_expiry_unlimited}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, 'propagation_is_expiry_unlimited': e.target.checked });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg mb-2">
                                                <p className="mainLabel mb-4">Propagation Subscription Type</p>
                                                <label className="secondaryText me-3">
                                                    <input
                                                        type="radio"
                                                        name="propagation_subscription_type"
                                                        id="month"
                                                        value="month"
                                                        checked={formData.propagation_subscription_type === 'month'}
                                                        className="me-1"
                                                        onChange={handleChange}
                                                    />
                                                    Monthly
                                                </label>
                                                <label className="secondaryText">
                                                    <input
                                                        type="radio"
                                                        name="propagation_subscription_type"
                                                        id="year"
                                                        value="year"
                                                        checked={formData.propagation_subscription_type === 'year'}
                                                        className="me-1"
                                                        onChange={handleChange}
                                                    />
                                                    Yearly
                                                </label>
                                            </div>

                                            <div className="col-12"></div>

                                            <br />
                                            <br />
                                            <br />

                                            <div className="col-12"></div>

                                            <div className="col-lg mb-2">
                                                <p className="mainLabel mb-4">Select Propagation Duration Type</p>
                                                <label className="secondaryText me-3">
                                                    <input
                                                        type="radio"
                                                        name="propagation_duration_type"
                                                        value="trial_period"
                                                        id="propagation_trial_period"
                                                        checked={formData.propagation_duration_type === 'trial_period'}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({ ...formData, [name]: value, 'propagation_term_in_days': '' })
                                                        }}
                                                    />
                                                    <label htmlFor="propagation_trial_period" className="ms-2">Trial Period</label>
                                                </label>
                                                <label className="secondaryText">
                                                    <input
                                                        type="radio"
                                                        name="propagation_duration_type"
                                                        value="term_in_days"
                                                        id="propagation_term_in_days"
                                                        checked={formData.propagation_duration_type === 'term_in_days'}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({ ...formData, [name]: value, 'propagation_trial_period': '' })
                                                        }}
                                                    />
                                                    <label htmlFor="propagation_term_in_days" className="ms-2">Term in Days</label>
                                                </label>
                                            </div>
                                            {
                                                formData.propagation_duration_type === 'trial_period' ?
                                                    <>
                                                        <div className="col-lg mb-2">
                                                            <CustomInput
                                                                label="Propagation Trial Period"
                                                                labelClass="mainLabel"
                                                                type="number"
                                                                name="propagation_trial_period"
                                                                value={formData.propagation_trial_period || ""}
                                                                placeholder="Trial Period"
                                                                inputClass="mainInput"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="col-lg mb-2">
                                                            <CustomInput
                                                                label="Propagation Term in Days"
                                                                labelClass="mainLabel"
                                                                type="number"
                                                                name="propagation_term_in_days"
                                                                value={formData.propagation_term_in_days || ""}
                                                                placeholder="Term in Days"
                                                                inputClass="mainInput"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </>
                                            }

                                            <div className="col-lg mb-2">
                                                <p className="mainLabel">Propagation Primary Info Slider</p>
                                                {sliderList && (
                                                    <select
                                                        name="propagation_primary_promo_user"
                                                        id="propagation_primary_promo_user"
                                                        className="mainInput w-100"
                                                        required
                                                        value={formData?.propagation_primary_promo_user?.id || ""}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({
                                                                ...formData, [name]: {
                                                                    ...formData[name],
                                                                    "id": Number(value)
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <option value={null}> Select </option>
                                                        {sliderList.length > 0 && sliderList.map((item, index) => (
                                                            <option key={index} value={item.id}>
                                                                {item.slider_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                            </div>

                                            <div className="col-lg mb-2">
                                                <p className="mainLabel">Propagation Household Info Slider</p>
                                                {sliderList && (
                                                    <select
                                                        name="propagation_household_promo_user"
                                                        id="propagation_household_promo_user"
                                                        className="mainInput w-100"
                                                        required
                                                        value={formData?.propagation_household_promo_user?.id || ""}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({
                                                                ...formData, [name]: {
                                                                    ...formData[name],
                                                                    "id": Number(value)
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <option value={null}> Select </option>
                                                        {sliderList.length > 0 && sliderList.map((item, index) => (
                                                            <option key={index} value={item.id}>
                                                                {item.slider_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                            </div>


                                            <div className="col-12 mb-2"></div>
                                            <br/>
                                            <br/>

                                            <div className="col-lg-3 mb-2">
                                                <p className="mainLabel">Select Propagation Asset</p>
                                                {assetData && (
                                                    <select
                                                        name="promo_propagation_asset"
                                                        id="promo_propagation_asset"
                                                        className="mainInput w-100"
                                                        required
                                                        value={formData?.promo_propagation_asset?.id || ""}
                                                        onChange={(event) => {
                                                            const { name, value } = event.target;
                                                            setFormData({
                                                                ...formData, [name]: {
                                                                    ...formData[name],
                                                                    "id": Number(value)
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <option value={null}> Select </option>
                                                        {assetData.length > 0 && assetData.map((item, index) => (
                                                            <option key={index} value={item.id}>
                                                                {item.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )}
                                            </div>
                                            <br />
                                            <br />
                                            <br />

                                            <div className="col-12"></div>

                                        </>
                                        :
                                        <></>
                                }


                                <div className="col-12"></div>

                                <br />
                                <br />
                                <br />

                                <div className="col-12"></div>

                            </div>

                            {loginError.error == true && (
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <p className="secondaryText">{loginError.text}</p>
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <div className="col-12">
                                    <CustomButton
                                        type="submit"
                                        variant="primaryButton"
                                        text={config.button_config.title}
                                    />
                                </div>
                            </div>

                        </form>

                        <br />
                        <br />
                        <br />

                    </div >
                    :
                    <div className="center-align">
                        <div className="spinner-border" role="status"></div>
                    </div>
            }


            <Modal
                show={showModal}
                centered
                className="swap-modal promo-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="pb-5 w-100 d-flex flex-column align-items-center">
                        <div className="text-end ms-auto">
                            <button
                                className="closeButton notButton ms-auto"
                                onClick={() => { setShowModal(false); }}
                            >
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className="cross-icon"
                                    color="white"
                                />
                            </button>
                        </div>
                        <div className="w-100 d-flex flex-column align-items-center" style={{ maxWidth: '550px' }}>
                            <div className="d-flex align-items-center mt-3 flex-column w-100">
                                <h2 className="mb-0 me-4">{"Copy Url"}</h2>
                                <br />
                                <br />
                                <div className="copy-text w-100">
                                    <input type="text" className="mainInput" value={`${FE_BASE_URL}/promo/${formData.code}`} disabled={true} />
                                    <button type='button' onClick={() => handleCopy(`${FE_BASE_URL}/promo/${formData.code}`)}>
                                        <FontAwesomeIcon
                                            icon={faClone}
                                        />
                                    </button>
                                </div>
                                <br />
                                <div className="copy-text w-100">
                                    <input type="text" className="mainInput" value={`${FE_BASE_URL}/promoint/${formData.code}`} disabled={true} />
                                    <button type='button' onClick={() => handleCopy(`${FE_BASE_URL}/promoint/${formData.code}`)}>
                                        <FontAwesomeIcon
                                            icon={faClone}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* copied modal */}
            <Modal
                show={copiedModal}
                centered
                className="swap-modal promo-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="p-3 py-5">
                        <h2 className="font-16 lh-16 m-0 fw-normal text-center">Link Copied!</h2>
                    </div>
                </Modal.Body>
            </Modal>


            {loader && <CustomLoader />}

        </div >
    )
}

export default PromoForm