import { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { Dropdown, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faEye,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";

import CustomTable from "./../../Components/CustomTable";
import { placeholderImage } from "../../Assets/images";

import "./style.css";
import { questionModal, successModal } from "../../Components/CustomModal";
import CustomFilters from "../../Components/CustomFilters";
import BASEURL from "../../Config/global";
import CustomPagination from "../../Components/CustomPagination";
import CustomLoader from "../../Components/CustomLoader";
import { disabledButtonByPermission } from "../../Components/Permissions";
import { PermissionsContext } from "../../Layout/PermissionContext";

const StoryManagement = () => {
  const [data, setData] = useState([]);
  const sortRef = useRef(null);
  const { permissions } = useContext(PermissionsContext)

  const [enteries, setEnteries] = useState(10);
  const [offset, setOffset] = useState(0);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const [sort, setSort] = useState("all");
  const [totalCount, setTotalCount] = useState(0);

  const [swapId, setSwapId] = useState(null);
  const [swapRecordId, setSwapRecordId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({ error: true, text: '' });
  const [loader, setLoader] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (currentPage != undefined) {
      setOffset(currentPage);
    }
  }, [currentPage])

  useEffect(() => {
    document.title = "Relax Scofa | Story Management";
  }, []);
  const modulesWisePermission = (condition) => {
    if (!permissions) {
      return 'd-none'
    }
    return disabledButtonByPermission(permissions, condition)
  }
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${BASEURL}/api/stories/?limit=${enteries}&offset=${offset}&start_date=${dateFrom}&end_date=${dateTo}&search=${filterSearchValue}`
        );
        if (response.data.error != true) {
          setData(response.data.data);
          setTotalCount(response.data.count);
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [enteries, filterSearchValue, dateTo, offset]);

  const deleteStory = async (id) => {
    try {
      const response = await axios.delete(`${BASEURL}/api/stories/${id}`);
      setData(data.filter((item) => item.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  const deleteStoryAction = (id, name) => {
    questionModal
      .fire({
        title: `Do you want to delete ${name} Story?`,
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteStory(id);
          successModal.fire({
            text: `${name} Story has been deleted`,
            confirmButtonText: "Continue",
          });
        }
      });
  };

  const storyHeader = [
    {
      key: "id",
      title: "S.No",
    },
    {
      key: "title",
      title: "Title",
    },
    {
      key: "type",
      title: "Type",
    },
    {
      key: "category",
      title: "Category",
    },
    {
      key: "subcategory",
      title: "Sub Category",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];

  const sortingValues = [
    {
      value: "all",
      text: "All",
    },
    {
      value: "status",
      text: "Status",
    },
    {
      value: "registered",
      text: "Registered Date",
    },
  ];

  const swipModal = (id, recordId) => {
    setError({ error: false, text: '' })
    setSwapId(id);
    // setSwapRecordId(recordId);
    setShowModal(true);
  }

  const setSorting = async () => {
    setError({ error: false, text: '' });
    let recordId = parseInt(sortRef.current.value, 10);
    if (recordId != '') {
      if (sortRef.current.value < data.length && sortRef.current.value > 0) {
        console.log(data[swapId - 1].id, data[recordId - 1].id);
        setShowModal(false);
        const formDataToSend = new FormData();
        formDataToSend.append("story_id_1", data[swapId - 1].id);
        formDataToSend.append("story_id_2", data[recordId - 1].id);
        try {
          const response = await axios.put(`${BASEURL}/api/stories/swap-stories/`, formDataToSend);
          setLoader(true);
          async function fetchData() {
            try {
              const response = await axios.get(
                `${BASEURL}/api/stories/?limit=${enteries}&offset=${offset}&start_date=${dateFrom}&end_date=${dateTo}&search=${filterSearchValue}`
              );
              if (response.data.error != true) {
                setData(response.data.data);
                setTotalCount(response.data.count);
                setLoader(false);
              } else {
                console.log(response.data.message);
              }
            } catch (error) {
              console.error(error);
            }
          }
          fetchData();

        } catch (error) {
          console.log(error);
        }
      } else {
        setError({ error: true, text: 'Please Enter Correct No' });
      }
    } else {
      setError({ error: true, text: 'Please Enter No' });
    }
  }

  const handleChangeEntries = (no) => {
    setOffset(0)
    setEnteries(no)
  }
  
  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-lg-8 mb-2">
              <h2 className="mainTitle">Story </h2>
            </div>
            <div className="col-lg-4 text-end mb-2">
              <Link
                to={"/story-management/add-story"}
                className={`customButton primaryButton ${modulesWisePermission('can_add')}`}
              >
                Add Story
              </Link>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomFilters
                enteries={enteries}
                totalCount={totalCount}
                entriesFilter={true}
                setEnteries={handleChangeEntries}
                filterSearch={true}
                filterSearchValue={filterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
                // dateFilter={true}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomTable headers={storyHeader}>
                <tbody>
                  {data &&
                    data.map((item, index) => (
                      <tr key={index}>
                        {/* <td>{index + 1}</td> */}
                        <td>
                          <button type="button" className="btn lightColor outline-0 border-0" onClick={() => swipModal(index + 1, item.id)}>{index + 1}</button>
                        </td>
                        <td className="text-capitalize">
                          <img
                            src={
                              item.thumbnail
                                ? `${item.thumbnail}`
                                : placeholderImage
                            }
                            alt="thumbnail"
                            className="thumbnail"
                          />
                          {item.title}
                        </td>
                        <td>{item.premium ? "Premium" : "Free"}</td>
                        <td>{item.storiescategoriesname}</td>
                        <td>{item?.subcategory_obj?.child_subcategory_name}</td>
                        <td>
                          <Dropdown className="tableDropdown">
                            <Dropdown.Toggle
                              variant="transparent"
                              className="notButton classicToggle"
                            >
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              align="end"
                              className="tableDropdownMenu"
                            >
                              <Link
                                to={`/story-management/story-details/${item.id}`}
                                className={`tableAction ${modulesWisePermission('can_view')}`}
                              >
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="tableActionIcon"
                                />
                                View
                              </Link>
                              <Link
                                to={`/story-management/edit-story/${item.id}`}
                                className={`tableAction ${modulesWisePermission('can_edit')}`}
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="tableActionIcon"
                                />
                                Edit
                              </Link>
                              <button
                                className={`tableAction ${modulesWisePermission('can_delete')}`}
                                onClick={() => {
                                  deleteStoryAction(item.id, item.title);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="tableActionIcon"
                                />
                                Delete
                              </button>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </CustomTable>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <CustomPagination
                enteries={enteries}
                totalCount={totalCount}
                setCurrentPage={setOffset}
                currentPage={offset}
              />
            </div>
          </div>
        </div>
      </DashboardLayout>

      {loader && <CustomLoader />}

      <Modal
        show={showModal}
        centered
        className="swap-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="pb-5 w-100 d-flex flex-column align-items-center">
            <div className="text-end ms-auto">
              <button
                className="closeButton notButton ms-auto"
                onClick={() => { setShowModal(false); }}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className="cross-icon"
                  color="white"
                />
              </button>
            </div>
            <div className="w-100 d-flex flex-column align-items-center" style={{ maxWidth: '500px' }}>
              <div className="d-flex align-items-center mt-3 pt-3">
                <h2 className="mb-0 me-4">Record <b className="mx-2">{swapId}</b> swap with </h2>
                <input type="number" ref={sortRef} onChange={() => setError({ error: false, text: '' })} />
              </div>
              {error?.error && <small className="text-danger mt-3">{error.text}</small>}
            </div>
            <div className="mt-3 pt-3 d-flex gap-3 justify-content-center">
              <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={setSorting}>
                <span className=''>Update</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};
export default StoryManagement;
