import { Pagination } from "react-bootstrap";

import "./style.css";
import { useEffect, useState } from "react";

const CustomPagination = ({ enteries, totalCount, currentPage = 0, setCurrentPage }) => {
    const [active, setActive] = useState(1);
    const [items, setItems] = useState([]);
    const pagesCount = Math.ceil(totalCount / enteries);
    const maxPagesToShow = 10;

    useEffect(() => {
        setActive(currentPage + 1);
    }, [currentPage]);

    useEffect(() => {
        const generatePaginationItems = () => {
            let itemsData = [];
            let startPage = Math.max(1, active - Math.floor(maxPagesToShow / 2));
            let endPage = Math.min(pagesCount, startPage + maxPagesToShow - 1);

            // Adjust start if we are near the end
            if (endPage - startPage < maxPagesToShow - 1) {
                startPage = Math.max(1, endPage - maxPagesToShow + 1);
            }

            // Add previous ellipsis
            if (startPage > 1) {
                itemsData.push(
                    <Pagination.Ellipsis key="ellipsis-start" />
                );
            }

            for (let number = startPage; number <= endPage; number++) {
                itemsData.push(
                    <Pagination.Item
                        onClick={() => {
                            setCurrentPage(number - 1);
                            setActive(number);
                        }}
                        key={number}
                        active={number === active}>
                        {number}
                    </Pagination.Item>
                );
            }


            if (endPage < pagesCount) {
                itemsData.push(
                    <Pagination.Ellipsis disabled={true} key="ellipsis-end" />
                );
            }

            setItems(itemsData);
        };

        generatePaginationItems();
    }, [enteries, totalCount, active, pagesCount]);

    const handleNextClick = () => {
        const newActive = Math.min(active + maxPagesToShow, pagesCount);
        setActive(newActive);
        setCurrentPage(newActive - 1);
    };

    const handlePrevClick = () => {
        const newActive = Math.max(active - maxPagesToShow, 1);
        setActive(newActive);
        setCurrentPage(newActive - 1);
    };
    const handleFirstClick = () => {
        setActive(1);
        setCurrentPage(0);
    };

    const handleLastClick = () => {
        const lastPage = pagesCount;
        setActive(lastPage);
        setCurrentPage(lastPage - 1);
    };
    if ((!totalCount && !enteries) || totalCount === 0) {
        return null
    }
    return (
        <>
            <div className="customPagination">
                <div className="row align-items-baseline">
                    <div className="col-lg-6">
                        {/* <p className="paginationText">{enteries} of {totalCount}</p> */}
                    </div>
                    <div className="col-lg-6">
                        <Pagination>
                            {
                                (active !== 1 && pagesCount > maxPagesToShow) && <>
                                    <Pagination.First onClick={handleFirstClick} />
                                    <Pagination.Prev
                                        disabled={active === 0}
                                        onClick={handlePrevClick}
                                    />
                                </>
                            }

                            {items}
                            {
                                (active !== pagesCount && pagesCount > maxPagesToShow)  && <>
                                    <Pagination.Next
                                        disabled={active === pagesCount - 1}
                                        onClick={handleNextClick} />
                                    <Pagination.Last onClick={handleLastClick} /></>
                            }


                        </Pagination>
                    </div>
                </div>
            </div>
        </>)
}

export default CustomPagination