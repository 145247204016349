import { useState } from "react";
import { useParams, useNavigate } from "react-router";

import axios from "axios";
import { questionModal, successModal } from "../../Components/CustomModal";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomButton from "../../Components/CustomButton";

// import "./style.css";
import BASEURL from "../../Config/global";
import { useEffect } from "react";
import { EditImageComponent } from "./ImageComponent";
import CustomLoader from "../../Components/CustomLoader";

const EditFeaturedMixCategory = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [inputValue, setinputValue] = useState("");
    const [thumbnail, setSoundThumbnail] = useState('');
    const [soundImage, setSoundImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {

                const url = `${BASEURL}/api/sounds/mixFeatureCategories/${id}`
                // const url = new URL(`/api/sounds/mix-feature-categories/${id}`, BASEURL).toString();

                const response = await axios.get(url);
                setinputValue(response.data.data[0].name);
                setSelectedImage(response.data.data[0].banner)
                setSoundThumbnail(response.data.data[0].banner)
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, []);

    const handleChange = (event) => {
        setinputValue(event.target.value);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);

        if (file === null || file === "undefined") {
            setSoundImage(null);
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setSoundImage(reader.result);
                setSelectedImage(reader.result);
                console.log(reader)
            };
        }
    };

    const handleRemoveImage = (event) => {
        setSoundImage(null)
        setSelectedImage(null)
        setSoundThumbnail(null)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (inputValue) {
            questionModal
                .fire({
                    title: "Do you want to update this category",
                    confirmButtonText: "Update",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        setLoader(true);
                        postData();
                    }
                });
        }
    };

    const postData = async () => {
        try {
            const body = JSON.stringify({
                "name": inputValue,
                "banner": selectedImage
            });
            const header = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await axios.patch(`${BASEURL}/api/sounds/mixFeatureCategories/${id}`, body, header);
            setLoader(false);
            if (!response.data.error) {
                successModal.fire({
                    text: 'Featured Mixes Category Updated Successfully',
                    confirmButtonText: "Continue",
                });
                navigate("/category-management");
            }
        } catch (error) {
            setLoader(false);
            console.error(error);
        }
    };

    return (
        <>
            <DashboardLayout>
                <div className="container-fluid">
                    <div className="row mb-3">
                        <div className="col-12 mb-2">
                            <h2 className="mainTitle">
                                <BackButton />
                                Edit Featured Mixes Category
                            </h2>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="row mb-5">
                                <div className="col-lg-6 mb-2">
                                    <div className="inputWrapper">
                                        <label className="mainLabel">Category</label>
                                        <div className="d-flex align-items-center gap-2">
                                            <input
                                                type="text"
                                                placeholder="Enter Category"
                                                required=""
                                                name="category"
                                                className="mainInput"
                                                value={inputValue || ""}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <EditImageComponent
                                    thumbnail={thumbnail}
                                    handleImageChange={handleImageChange}
                                    soundImage={soundImage}
                                    handleRemoveImage={handleRemoveImage}
                                />
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <CustomButton
                                        type="submit"
                                        variant="primaryButton"
                                        text="Update"
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>

            {loader && <CustomLoader />}
        </>
    );
};
export default EditFeaturedMixCategory;
